import React from "react"
import { PageProps, Link } from "gatsby"
import { Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HorizontalLine from "../components/horizontalLine"

import './../styles/page.css'

const AboutPage = (props: PageProps) => (
  <Layout>
    <SEO title="about" />
    <Container className="page-frame">
        <Row className="page-heading">
            about
        </Row>
        <Row className="page-body">
            I'm a technologist and engineer based in the US.
            <br /><br />
            I work in security consulting, and my day job involves assessing solutions against security and engineering requirements before they are deployed in various environments.
            <br /><br />
            ~~~
            <br /><br />
            I earned my undergraduate degree in computer science and am currently enrolled in a professional certificate program in cybersecurity. 
            <br /><br />
            Throughout my undergraduate program, I also took extensive courses in Arabic studies and in applied mathematics to pursue a minor.
            <br /><br />
            In addition to my education, I hold the following certifications:
            <br />
            + Certified Computer Forensic Examiner (IACRB)
            <br />
            + Certified Mobile Forensic Examiner (IACRB)
            <br />
            + Security+ (CompTIA)
            <br /><br />
            ~~~
            <br /><br />
            When not behind a computer, I pursue and nurture my interests in art, media, and design; my background in technology, art and design, and culture informs my work.
            <br /><br />
            {/* <br /><br />
            When not behind a computer, I pursue and nurture my interests in art, media, and design; I believe when the uninhibited expression of the human spirit inspires empathy and diversity, their synergy become a powerful driver of innovation.
            <br /><br />
            I am also interested in studying diasporic cultures and their history and preservation. I love studying people.
            <br /><br />
            My background in technology, art and design, and culture informs my work.
            <br /><br /> */}
            ~~~
            <br /><br />
            Want to get in touch? Email me at my personal email (if you know it) or at: forkbum [at] protonmail [dot] com.
        </Row>
    </Container>
  </Layout>
)

export default AboutPage

// export default function AboutPage({ data }) {
//     const post = data.markdownRemark;
//     const ( path, title ) = post.frontmatter;

//     return (
//         <Layout>
//             <SEO title="About Trish" />
//             <Container className="page-frame">
//                 <Row className="page-heading">
//                     about
//                 </Row>
//                 <Row className="page-body">
//                     I'm an engineer and technologist based in the US.
//                 </Row>
//                 <Row className="page-body">
//                     Currently in my "day job," I help ensure lab and client environments are secure against various requirements.
//                 </Row>
//             </Container>
//         </Layout>
//     )
// }

// export const aboutPageQuery = graphql`
// query AboutPageQuery($path: String!) {
//     markdownRemark(frontmatter: { path: { eq: $path }}) {
//         frontmatter {
//             author
//             date
//             title
//             path
//         }
//         html
//     }
// }
// `

// export default function Template({ data }) {
//     const post = data.markdownRemark;
//     const { title, author, date } = post.frontmatter;